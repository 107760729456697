<ng-template>
    <div (click)="slideClicked.emit()" class="carousel-slide">
        <div class="relative w-full h-full">
            <img *ngIf="image" [stlcProtectedSrc]="image" class="object-cover h-full w-full" />
            <div *ngIf="!image" class="bg-gray-100 object-cover h-full w-full"></div>
        </div>
        <div class="carousel-slide-content"><ng-content></ng-content></div>
        <div *ngIf="!hideOverlay" class="carousel-slide-overlay" [style.background-color]="overlayColor"></div>
    </div>
</ng-template>
