import { Inject, Pipe, PipeTransform } from '@angular/core';
import { I18NEXT_SERVICE, ITranslationService } from 'angular-i18next';

import { getPitchTypeText } from '@stlc/pitch-grip/shared';

@Pipe({
    name: 'pitchGripPitchType',
})
export class PitchGripPitchTypePipe implements PipeTransform {
    constructor(@Inject(I18NEXT_SERVICE) i18next: ITranslationService) {
        i18next.loadNamespaces(['pitchType']);
    }

    transform(value: any): string {
        return getPitchTypeText(value);
    }
}
