import { Component, EventEmitter, Inject, Input, Output } from '@angular/core';
import { I18NEXT_SERVICE, ITranslationService } from 'angular-i18next';

import { includes, isNil, map } from '@stlc/lodash';
import { PitchGripFragment } from '@stlc/pitch-grip/data-access';
import { UiMediaLightboxDialogComponent } from '@stlc/ui';
import { UiConfirmationDialogComponent, UiDialogService } from '@stlc/ui/dialog';
import { JwtAuthService } from '@stlc/user';

import { PitchGripUiDetailDialogComponent } from '..';

@Component({
    selector: 'pitch-grip-ui-card',
    templateUrl: './card.component.html',
    styleUrls: ['./card.component.scss'],
})
export class PitchGripUiCardComponent {
    @Input() grip: PitchGripFragment | undefined;
    @Input() myGripsVariant = false;

    @Output()
    bookmarkChange = new EventEmitter();

    @Output()
    moveToPrivate = new EventEmitter();

    @Output()
    requestPublic = new EventEmitter();

    @Output()
    deleteGrip = new EventEmitter();

    showDetailModal = false;

    constructor(
        private dialog: UiDialogService,
        public jwtAuthService: JwtAuthService,
        @Inject(I18NEXT_SERVICE) private i18next: ITranslationService
    ) {}

    get showMoveToPrivateButton() {
        return includes(['Pending', 'Rejected', 'Public'], this.grip?.status);
    }

    bookmarkClick() {
        this.bookmarkChange.emit();
    }

    moveToPrivateClick() {
        if (includes(['Public', 'Pending'], this.grip?.status)) {
            this.dialog
                .open<UiConfirmationDialogComponent, any, boolean>(UiConfirmationDialogComponent, {
                    data: {
                        title: this.i18next.t('pitchGrip:button.moveToPrivate'),
                        message: this.i18next.t('pitchGrip:moveToPrivateDialogMessage'),
                        confirmButtonText: this.i18next.t('pitchGrip:button.moveToPrivate'),
                        cancelButtonText: this.i18next.t('common:cancel'),
                    },
                    height: '',
                    position: {
                        top: '50vh',
                    },
                    minWidth: '300px',
                    maxWidth: '640px',
                    panelClass: ['stlc-dialog-panel-middle'],
                })
                .beforeClosed()
                .subscribe((confirmed) => {
                    if (confirmed) {
                        this.moveToPrivate.emit();
                    }
                });
        } else {
            this.moveToPrivate.emit();
        }
    }

    openLightbox(activeIndex: number) {
        if (this.grip?.ready) {
            const { id, media } = this.grip;
            UiMediaLightboxDialogComponent.open(this.dialog, {
                activeIndex,
                items: map(media, (media) => ({
                    url: `/grips/media/${id}?filename=${media.filename}`,
                    thumbnail: !isNil(media.thumbnail) ? `/grips/media/${id}?filename=${media.thumbnail}` : undefined,
                    type: media.type?.startsWith('video') ? 'video' : 'image',
                    loop: media.loop,
                })),
            });
        }
    }

    openDetailDialog() {
        if (this.grip) {
            const dialogRef = PitchGripUiDetailDialogComponent.open(this.dialog, { grip: this.grip });
            dialogRef.afterClosed().subscribe((action?: string) => {
                if (!isNil(action)) {
                    switch (action) {
                        case 'delete':
                            this.deleteGrip.emit();
                            break;
                        case 'public':
                            this.dialog
                                .open<UiConfirmationDialogComponent, any, boolean>(UiConfirmationDialogComponent, {
                                    data: {
                                        title: this.i18next.t('pitchGrip:requestPublicConfirmTitle'),
                                        message: this.i18next.t('pitchGrip:requestPublicConfirmMessage'),
                                        confirmButtonText: this.i18next.t('pitchGrip:button.requestPublic'),
                                        cancelButtonText: this.i18next.t('common:cancel'),
                                    },
                                    height: '',
                                    position: {
                                        top: '50vh',
                                    },
                                    minWidth: '300px',
                                    maxWidth: '640px',
                                    panelClass: ['stlc-dialog-panel-middle'],
                                })
                                .beforeClosed()
                                .subscribe((confirmed) => {
                                    if (confirmed) {
                                        this.requestPublic.emit();
                                    }
                                });
                            break;
                    }
                }
            });
        }
    }

    detailModalClose() {
        this.showDetailModal = false;
    }
}
