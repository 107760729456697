import { Component, Inject, OnDestroy } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { PitchGripFragment } from '@stlc/pitch-grip/data-access';
import { UiDialogService } from '@stlc/ui/dialog';

interface DialogData {
    grip: PitchGripFragment;
}

@Component({
    selector: 'pitch-grip-ui-detail-dialog',
    templateUrl: './detail-dialog.component.html',
    styleUrls: ['./detail-dialog.component.scss'],
})
export class PitchGripUiDetailDialogComponent implements OnDestroy {
    grip!: PitchGripFragment;

    private readonly destroy = new Subject<void>();

    static open(dialog: UiDialogService, { grip }: DialogData) {
        return dialog.open<PitchGripUiDetailDialogComponent, DialogData, string>(PitchGripUiDetailDialogComponent, {
            data: {
                grip,
            },
            height: '',
            position: {
                top: '50vh',
            },
            minWidth: '300px',
            width: '90vw',
            maxWidth: '640px',
            panelClass: ['stlc-dialog-panel-middle'],
        });
    }

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: DialogData,
        private dialogRef: MatDialogRef<PitchGripUiDetailDialogComponent, string>
    ) {
        this.dialogRef
            .backdropClick()
            .pipe(takeUntil(this.destroy))
            .subscribe(() => {
                this.close();
            });

        this.grip = this.data?.grip;
    }

    ngOnDestroy(): void {
        this.destroy.next();
        this.destroy.complete();
    }

    close() {
        this.dialogRef.close();
    }

    handleDelete() {
        this.dialogRef.close('delete');
    }

    handleRequestPublic() {
        this.dialogRef.close('public');
    }
}
