import { gql } from 'apollo-angular';
import { Injectable } from '@angular/core';
import * as Apollo from 'apollo-angular';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** A date string, such as 2007-12-03, compliant with the `full-date` format outlined in section 5.6 of the RFC 3339 profile of the ISO 8601 standard for representation of dates and times using the Gregorian calendar. */
  Date: any;
  /** A date-time string at UTC, such as 2019-12-03T09:54:33Z, compliant with the date-time format. */
  DateTime: any;
};

export type ChangeGripStatusInput = {
  id: Scalars['String'];
  status: Scalars['String'];
  statusReason?: InputMaybe<Scalars['String']>;
};

export type CreatePitchGripInput = {
  armSlot: Scalars['String'];
  date: Scalars['Date'];
  description?: InputMaybe<Scalars['String']>;
  filePaths: Array<Scalars['String']>;
  pitchType: Scalars['String'];
  pitcherSourceId: Scalars['Int'];
  pitcherSourceIdOrigin: Scalars['String'];
  pitcherThrows: Scalars['String'];
  shareWithEveryone: Scalars['Boolean'];
  source?: InputMaybe<Scalars['String']>;
};

export type FetchFilterInfoInput = {
  pitcherIds?: InputMaybe<Array<Scalars['String']>>;
};

export type FetchGripsInput = {
  armSlot?: InputMaybe<Array<Scalars['String']>>;
  id?: InputMaybe<Array<Scalars['String']>>;
  isBookmarked?: InputMaybe<Scalars['Boolean']>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Scalars['String']>;
  orderDirection?: InputMaybe<Scalars['String']>;
  pitchType?: InputMaybe<Array<Scalars['String']>>;
  pitcherId?: InputMaybe<Array<Scalars['String']>>;
  pitcherThrows?: InputMaybe<Array<Scalars['String']>>;
  status?: InputMaybe<Array<Scalars['String']>>;
};

export type FetchGripsQueryVariables = Exact<{
  input: FetchGripsInput;
}>;


export type FetchGripsQuery = { __typename?: 'Query', pitchGripPagination: { __typename?: 'PitchGripPaginatedResult', offset: number, limit: number, count: number, returning: Array<{ __typename?: 'PitchGrip', id: string, createdById?: number | null, pitcherId?: string | null, pitcherSourceId: number, pitcherSourceIdOrigin: string, pitcherName: string, createdAt: any, date: any, pitchType: string, armSlot: string, pitcherThrows: string, description?: string | null, status: string, statusReason?: string | null, isBookmarked: boolean, canEdit: boolean, ready: boolean, videoIndices: Array<number>, source?: { __typename?: 'PitchGripSource', id: string, url: string } | null, media: Array<{ __typename?: 'PitchGripMedia', id: string, type: string, filename: string, thumbnail?: string | null, ready: boolean, loop: boolean, orderNum: number }> }> } };

export type FetchMyGripsQueryVariables = Exact<{
  input: FetchGripsInput;
}>;


export type FetchMyGripsQuery = { __typename?: 'Query', pitchGripMyGripsPagination: { __typename?: 'PitchGripPaginatedResult', offset: number, limit: number, count: number, returning: Array<{ __typename?: 'PitchGrip', id: string, createdById?: number | null, pitcherId?: string | null, pitcherSourceId: number, pitcherSourceIdOrigin: string, pitcherName: string, createdAt: any, date: any, pitchType: string, armSlot: string, pitcherThrows: string, description?: string | null, status: string, statusReason?: string | null, isBookmarked: boolean, canEdit: boolean, ready: boolean, videoIndices: Array<number>, source?: { __typename?: 'PitchGripSource', id: string, url: string } | null, media: Array<{ __typename?: 'PitchGripMedia', id: string, type: string, filename: string, thumbnail?: string | null, ready: boolean, loop: boolean, orderNum: number }> }> } };

export type FetchFilterInfoQueryVariables = Exact<{
  input: FetchFilterInfoInput;
}>;


export type FetchFilterInfoQuery = { __typename?: 'Query', pitchGripFilterInfo: { __typename?: 'PitchGripFilterInfo', count: number, pitchTypes?: Array<string> | null, armSlots?: Array<string> | null, pitchers?: Array<{ __typename?: 'Player', id: string, sourceId: number, sourceIdOrigin: string, fullName: string }> | null } };

export type PitchersWithGripsSearchQueryVariables = Exact<{
  query: Scalars['String'];
}>;


export type PitchersWithGripsSearchQuery = { __typename?: 'Query', pitchGripPlayersWithGripsSearch: Array<{ __typename?: 'Player', id: string, sourceId: number, sourceIdOrigin: string, fullName: string }> };

export type GetPitchGripQueryVariables = Exact<{
  id: Scalars['String'];
}>;


export type GetPitchGripQuery = { __typename?: 'Query', pitchGripGetById: { __typename?: 'PitchGrip', id: string, createdById?: number | null, pitcherId?: string | null, pitcherSourceId: number, pitcherSourceIdOrigin: string, pitcherName: string, createdAt: any, date: any, pitchType: string, armSlot: string, pitcherThrows: string, description?: string | null, status: string, statusReason?: string | null, isBookmarked: boolean, canEdit: boolean, ready: boolean, videoIndices: Array<number>, source?: { __typename?: 'PitchGripSource', id: string, url: string } | null, media: Array<{ __typename?: 'PitchGripMedia', id: string, type: string, filename: string, thumbnail?: string | null, ready: boolean, loop: boolean, orderNum: number }> } };

export type ChangeGripStatusMutationVariables = Exact<{
  input: ChangeGripStatusInput;
}>;


export type ChangeGripStatusMutation = { __typename?: 'Mutation', pitchGripChangeStatus: { __typename?: 'PitchGrip', id: string, createdById?: number | null, pitcherId?: string | null, pitcherSourceId: number, pitcherSourceIdOrigin: string, pitcherName: string, createdAt: any, date: any, pitchType: string, armSlot: string, pitcherThrows: string, description?: string | null, status: string, statusReason?: string | null, isBookmarked: boolean, canEdit: boolean, ready: boolean, videoIndices: Array<number>, source?: { __typename?: 'PitchGripSource', id: string, url: string } | null, media: Array<{ __typename?: 'PitchGripMedia', id: string, type: string, filename: string, thumbnail?: string | null, ready: boolean, loop: boolean, orderNum: number }> } };

export type UploadPitchGripMutationVariables = Exact<{
  input: CreatePitchGripInput;
}>;


export type UploadPitchGripMutation = { __typename?: 'Mutation', pitchGripCreateGrip: { __typename?: 'PitchGrip', id: string, pitcherId?: string | null, pitcherName: string, date: any, createdAt: any, pitchType: string, armSlot: string, pitcherThrows: string, description?: string | null, status: string, statusReason?: string | null, mediaUploadUrls?: Array<string> | null, source?: { __typename?: 'PitchGripSource', url: string } | null, media: Array<{ __typename?: 'PitchGripMedia', type: string }> } };

export type BookmarkPitchGripMutationVariables = Exact<{
  id: Scalars['String'];
}>;


export type BookmarkPitchGripMutation = { __typename?: 'Mutation', pitchGripBookmarkGrip: { __typename?: 'PitchGrip', id: string, createdById?: number | null, pitcherId?: string | null, pitcherSourceId: number, pitcherSourceIdOrigin: string, pitcherName: string, createdAt: any, date: any, pitchType: string, armSlot: string, pitcherThrows: string, description?: string | null, status: string, statusReason?: string | null, isBookmarked: boolean, canEdit: boolean, ready: boolean, videoIndices: Array<number>, source?: { __typename?: 'PitchGripSource', id: string, url: string } | null, media: Array<{ __typename?: 'PitchGripMedia', id: string, type: string, filename: string, thumbnail?: string | null, ready: boolean, loop: boolean, orderNum: number }> } };

export type PitchGripMediaFragment = { __typename?: 'PitchGripMedia', id: string, type: string, filename: string, thumbnail?: string | null, ready: boolean, loop: boolean, orderNum: number };

export type PitchGripPitcherFragment = { __typename?: 'Player', id: string, sourceId: number, sourceIdOrigin: string, fullName: string };

export type UploadedPitchGripFragment = { __typename?: 'PitchGrip', id: string, pitcherId?: string | null, pitcherName: string, date: any, createdAt: any, pitchType: string, armSlot: string, pitcherThrows: string, description?: string | null, status: string, statusReason?: string | null, mediaUploadUrls?: Array<string> | null, source?: { __typename?: 'PitchGripSource', url: string } | null, media: Array<{ __typename?: 'PitchGripMedia', type: string }> };

export type PitchGripFragment = { __typename?: 'PitchGrip', id: string, createdById?: number | null, pitcherId?: string | null, pitcherSourceId: number, pitcherSourceIdOrigin: string, pitcherName: string, createdAt: any, date: any, pitchType: string, armSlot: string, pitcherThrows: string, description?: string | null, status: string, statusReason?: string | null, isBookmarked: boolean, canEdit: boolean, ready: boolean, videoIndices: Array<number>, source?: { __typename?: 'PitchGripSource', id: string, url: string } | null, media: Array<{ __typename?: 'PitchGripMedia', id: string, type: string, filename: string, thumbnail?: string | null, ready: boolean, loop: boolean, orderNum: number }> };

export const PitchGripPitcherFragmentDoc = gql`
    fragment PitchGripPitcher on Player {
  id
  sourceId
  sourceIdOrigin
  fullName
}
    `;
export const UploadedPitchGripFragmentDoc = gql`
    fragment UploadedPitchGrip on PitchGrip {
  id
  pitcherId
  pitcherName
  date
  createdAt
  pitchType
  armSlot
  pitcherThrows
  description
  status
  statusReason
  source {
    url
  }
  media {
    type
  }
  mediaUploadUrls
}
    `;
export const PitchGripMediaFragmentDoc = gql`
    fragment PitchGripMedia on PitchGripMedia {
  id
  type
  filename
  thumbnail
  ready
  loop
  orderNum
}
    `;
export const PitchGripFragmentDoc = gql`
    fragment PitchGrip on PitchGrip {
  id
  createdById
  pitcherId
  pitcherSourceId
  pitcherSourceIdOrigin
  pitcherName
  createdAt
  date
  pitchType
  armSlot
  pitcherThrows
  description
  status
  statusReason
  source {
    id
    url
  }
  media {
    ...PitchGripMedia
  }
  isBookmarked
  canEdit
  ready
  videoIndices
}
    ${PitchGripMediaFragmentDoc}`;
export const FetchGripsDocument = gql`
    query FetchGrips($input: FetchGripsInput!) {
  pitchGripPagination(input: $input) {
    offset
    limit
    count
    returning {
      ...PitchGrip
    }
  }
}
    ${PitchGripFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class FetchGripsGQL extends Apollo.Query<FetchGripsQuery, FetchGripsQueryVariables> {
    override document = FetchGripsDocument;
    override client = 'gateway';
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const FetchMyGripsDocument = gql`
    query FetchMyGrips($input: FetchGripsInput!) {
  pitchGripMyGripsPagination(input: $input) {
    offset
    limit
    count
    returning {
      ...PitchGrip
    }
  }
}
    ${PitchGripFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class FetchMyGripsGQL extends Apollo.Query<FetchMyGripsQuery, FetchMyGripsQueryVariables> {
    override document = FetchMyGripsDocument;
    override client = 'gateway';
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const FetchFilterInfoDocument = gql`
    query FetchFilterInfo($input: FetchFilterInfoInput!) {
  pitchGripFilterInfo(input: $input) {
    count
    pitchers {
      ...PitchGripPitcher
    }
    pitchTypes
    armSlots
  }
}
    ${PitchGripPitcherFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class FetchFilterInfoGQL extends Apollo.Query<FetchFilterInfoQuery, FetchFilterInfoQueryVariables> {
    override document = FetchFilterInfoDocument;
    override client = 'gateway';
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const PitchersWithGripsSearchDocument = gql`
    query PitchersWithGripsSearch($query: String!) {
  pitchGripPlayersWithGripsSearch(query: $query) {
    ...PitchGripPitcher
  }
}
    ${PitchGripPitcherFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class PitchersWithGripsSearchGQL extends Apollo.Query<PitchersWithGripsSearchQuery, PitchersWithGripsSearchQueryVariables> {
    override document = PitchersWithGripsSearchDocument;
    override client = 'gateway';
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetPitchGripDocument = gql`
    query GetPitchGrip($id: String!) {
  pitchGripGetById(id: $id) {
    ...PitchGrip
  }
}
    ${PitchGripFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class GetPitchGripGQL extends Apollo.Query<GetPitchGripQuery, GetPitchGripQueryVariables> {
    override document = GetPitchGripDocument;
    override client = 'gateway';
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const ChangeGripStatusDocument = gql`
    mutation ChangeGripStatus($input: ChangeGripStatusInput!) {
  pitchGripChangeStatus(input: $input) {
    ...PitchGrip
  }
}
    ${PitchGripFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class ChangeGripStatusGQL extends Apollo.Mutation<ChangeGripStatusMutation, ChangeGripStatusMutationVariables> {
    override document = ChangeGripStatusDocument;
    override client = 'gateway';
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UploadPitchGripDocument = gql`
    mutation UploadPitchGrip($input: CreatePitchGripInput!) {
  pitchGripCreateGrip(input: $input) {
    ...UploadedPitchGrip
  }
}
    ${UploadedPitchGripFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class UploadPitchGripGQL extends Apollo.Mutation<UploadPitchGripMutation, UploadPitchGripMutationVariables> {
    override document = UploadPitchGripDocument;
    override client = 'gateway';
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const BookmarkPitchGripDocument = gql`
    mutation BookmarkPitchGrip($id: String!) {
  pitchGripBookmarkGrip(id: $id) {
    ...PitchGrip
  }
}
    ${PitchGripFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class BookmarkPitchGripGQL extends Apollo.Mutation<BookmarkPitchGripMutation, BookmarkPitchGripMutationVariables> {
    override document = BookmarkPitchGripDocument;
    override client = 'gateway';
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }