import { Injectable, ModuleWithProviders, NgModule } from '@angular/core';
import { HAMMER_GESTURE_CONFIG, HammerGestureConfig, HammerModule } from '@angular/platform-browser';

import { StlcSharedModule } from '@stlc/angular/shared';

import { MatCarouselComponent, MatCarouselSlideComponent } from './components';

// https://github.com/angular/angular/issues/10541#issuecomment-300761387
@Injectable()
export class UiCarouselHammerConfig extends HammerGestureConfig {
    override overrides = {
        pinch: { enable: false },
        rotate: { enable: false },
    };
}
@NgModule({
    declarations: [MatCarouselComponent, MatCarouselSlideComponent],
    imports: [StlcSharedModule, HammerModule],
    exports: [MatCarouselComponent, MatCarouselSlideComponent],
})
export class UiCarouselModule {
    static forRoot(): ModuleWithProviders<UiCarouselModule> {
        return {
            ngModule: UiCarouselModule,
            providers: [{ provide: HAMMER_GESTURE_CONFIG, useClass: UiCarouselHammerConfig }],
        };
    }
}
