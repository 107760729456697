import { Component, EventEmitter, Input, Output, TemplateRef, ViewChild } from '@angular/core';

@Component({
    selector: 'ui-carousel-slide',
    templateUrl: './carousel-slide.component.html',
    styleUrls: ['./carousel-slide.component.scss'],
})
export class MatCarouselSlideComponent {
    @Input() public image?: string;
    @Input() public overlayColor = '#00000040';
    @Input() public hideOverlay = false;
    @Input() public disabled = false;

    @Output() public slideClicked = new EventEmitter();

    @ViewChild(TemplateRef) public templateRef!: TemplateRef<any>;
}
