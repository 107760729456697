import { Component, Input } from '@angular/core';

import { PitchGripFragment } from '@stlc/pitch-grip/data-access';

@Component({
    selector: 'pitch-grip-recently-uploaded-grips',
    templateUrl: './recently-uploaded-grips.component.html',
    styleUrls: ['./recently-uploaded-grips.component.scss'],
})
export class RecentlyUploadedGripsComponent {
    @Input()
    grips: PitchGripFragment[] = [];
}
