<div class="flex flex-row flex-wrap justify-center items-center" *ngIf="grips.length > 0">
    <div class="m-1 border border-gray-100 rounded w-28" *ngFor="let grip of grips">
        <div class="text-center text-base p-2 font-bold border-b border-gray-50">
            {{ grip.pitchType | pitchGripPitchType | i18next }}
        </div>
        <div class="w-full h-28">
            <img
                class="object-cover h-full w-full"
                stlcProtectedSrc="/grips/media/{{ grip.id }}?filename={{
                    grip.media[0].thumbnail || grip.media[0].filename
                }}"
            />
        </div>
        <div
            class="flex flex-col justify-center items-center text-center text-xs text-gray-400 border-t border-gray-50 p-2"
        >
            <span class="max-w-full whitespace-nowrap overflow-hidden text-ellipsis">{{
                grip.pitcherName.split(', ').reverse().join(' ')
            }}</span>
        </div>
    </div>
</div>

<div *ngIf="grips.length === 0" class="flex items-center justify-center p-2 space-x-2">
    <span>All caught up! Explore pitch grips</span>
    <mat-icon>arrow_forward</mat-icon>
</div>
