<mat-card class="w-full max-w-lg" *ngIf="grip" [class.opacity-50]="!grip.ready">
    <mat-card-header>
        <mat-card-title>
            {{ grip.pitchType | pitchGripPitchType | i18next }}
            <span *ngIf="!grip.ready" [innerHTML]="'pitchGrip:gripProcessing' | i18next"></span>
        </mat-card-title>
        <mat-card-subtitle *ngIf="myGripsVariant">{{ grip.createdAt | date: 'mediumDate' }}</mat-card-subtitle>
        <mat-card-subtitle *ngIf="!myGripsVariant"
            >{{ grip.pitcherName.split(', ').reverse().join(' ') }} |
            {{ grip.createdAt | date: 'mediumDate' }}</mat-card-subtitle
        >
        <div class="flex-grow flex justify-end">
            <mat-spinner *ngIf="!grip.ready" diameter="24"></mat-spinner>
            <button
                type="button"
                mat-icon-button
                (click)="bookmarkClick()"
                *ngIf="grip.ready && !myGripsVariant"
                [matTooltip]="grip.isBookmarked ? 'Remove bookmark' : 'Add bookmark'"
                matTooltipShowDelay="1000"
                matTooltipPosition="above"
                [color]="grip.isBookmarked ? 'primary' : undefined"
            >
                <mat-icon *ngIf="grip.isBookmarked">bookmark</mat-icon>
                <mat-icon *ngIf="!grip.isBookmarked">bookmark_border</mat-icon>
            </button>
        </div>
    </mat-card-header>
    <div mat-card-image class="cursor-pointer">
        <ui-carousel
            [hideArrows]="grip.media.length <= 1"
            [hideIndicators]="grip.media.length <= 1"
            [timings]="'100ms ease-in'"
            [maxWidth]="'100%'"
            [loop]="true"
            [autoplay]="false"
            [maintainAspectRatio]="true"
            [proportion]="75"
            [useMouseWheel]="false"
            [useKeyboard]="true"
            [slides]="grip.media.length"
            [showPlayButton]="grip.videoIndices"
        >
            <ng-container *ngFor="let slide of grip.media; let i = index" [ngSwitch]="!slide.ready">
                <ui-carousel-slide *ngSwitchCase="true"></ui-carousel-slide>
                <ui-carousel-slide
                    *ngSwitchDefault
                    image="/grips/media/{{ grip.id }}?filename={{ slide.thumbnail || slide.filename }}"
                    (slideClicked)="openLightbox(i)"
                >
                </ui-carousel-slide>
            </ng-container>
        </ui-carousel>
    </div>
    <mat-card-content class="flex flex-row justify-between items-center">
        <ul class="flex-initial stlc-list-inline-vbar text-gray-400 mx-4" *ngIf="!myGripsVariant">
            <li *ngIf="grip.pitcherThrows">{{ grip.pitcherThrows }}HP</li>
            <li *ngIf="grip.armSlot">{{ grip.armSlot }}</li>
        </ul>
        <div class="flex-initial" [class.pt-1]="showMoveToPrivateButton" *ngIf="myGripsVariant">
            <button type="button" (click)="moveToPrivateClick()" mat-button *ngIf="showMoveToPrivateButton">
                <span [innerHTML]="'pitchGrip:button.moveToPrivate' | i18next"></span>
            </button>
        </div>
        <div class="flex-initial justify-end">
            <button
                type="button"
                mat-icon-button
                color="gray"
                *ngIf="
                    grip.description || grip.source || (grip.status === 'Rejected' && grip.statusReason) || grip.canEdit
                "
                (click)="openDetailDialog()"
                matTooltip="More info"
                matTooltipShowDelay="1000"
                matTooltipPosition="above"
            >
                <mat-icon>info_outline</mat-icon>
            </button>
        </div>
    </mat-card-content>
</mat-card>
