import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { StlcSharedModule } from '@stlc/angular/shared';
import { StlcI18nModule } from '@stlc/i18n/core';
import { UiCarouselModule } from '@stlc/ui/carousel';

import {
    PitchGripUiCardComponent,
    PitchGripUiDetailDialogComponent,
    RecentlyUploadedGripsComponent,
} from './components';
import { PitchGripMaxMediaSizeDirective } from './directives';
import { PitchGripPitchTypePipe } from './pipes';

@NgModule({
    imports: [CommonModule, StlcSharedModule, StlcI18nModule, UiCarouselModule.forRoot()],
    declarations: [
        PitchGripMaxMediaSizeDirective,
        PitchGripPitchTypePipe,
        PitchGripUiCardComponent,
        PitchGripUiDetailDialogComponent,
        RecentlyUploadedGripsComponent,
    ],
    exports: [
        PitchGripPitchTypePipe,
        PitchGripUiCardComponent,
        PitchGripUiDetailDialogComponent,
        RecentlyUploadedGripsComponent,
    ],
})
export class PitchGripUiModule {}
