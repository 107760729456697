<!-- <h1 mat-dialog-title>
    <span
        >{{ grip.pitchType | pitchGripPitchType | i18next }}<br /><small
            >{{ grip.pitcherName.split(', ').reverse().join(' ') }} | {{ grip.createdAt | date: 'mediumDate':'UTC' }}</small
        ></span
    >
</h1> -->
<div mat-dialog-title class="stlc-dialog-title">
    <div class="stlc-dialog-heading">{{ grip.pitchType | pitchGripPitchType | i18next }}</div>
    <div class="stlc-dialog-subheading">
        {{ grip.pitcherName.split(', ').reverse().join(' ') }} | {{ grip.createdAt | date: 'mediumDate':'UTC' }}
    </div>
</div>
<button mat-dialog-close mat-icon-button class="stlc-dialog-close">
    <mat-icon>close</mat-icon>
</button>
<div mat-dialog-content class="stlc-mat-dialog-content">
    <ng-container *ngIf="grip.statusReason !== null && grip.status === 'Rejected'">
        <div class="flex-initial text-left">
            <div class="text-gray-500" [innerHTML]="'pitchGrip:rejectedReasonLabel' | i18next"></div>
            <div class="pt-1 text-md">
                <span>{{ grip.statusReason }}</span>
            </div>
        </div>
        <mat-divider class="my-5" *ngIf="grip.source"></mat-divider>
    </ng-container>
    <ng-container *ngIf="grip.description !== null">
        <div class="flex-initial text-left">
            <div class="text-gray-500" [innerHTML]="'pitchGrip:descriptionLabel' | i18next"></div>
            <div class="pt-1 text-md">
                <span>{{ grip.description }}</span>
            </div>
        </div>
        <mat-divider class="my-5" *ngIf="grip.source"></mat-divider>
    </ng-container>
    <ng-container *ngIf="grip.source">
        <div class="flex-initial text-left">
            <div class="text-gray-500" [innerHTML]="'pitchGrip:sourceLabel' | i18next"></div>
            <div class="pt-1 text-md">
                <a [href]="grip.source?.url" target="_blank" class="break-words">{{ grip.source?.url }}</a>
            </div>
        </div>
        <mat-divider class="my-5" *ngIf="grip.canEdit"></mat-divider>
    </ng-container>
</div>
<div mat-dialog-actions *ngIf="grip.canEdit" align="end">
    <button type="button" mat-flat-button color="danger" (click)="handleDelete()">
        {{ 'pitchGrip:button.delete' | i18next }}
    </button>
    <button *ngIf="grip.status === 'Private'" type="button" mat-button (click)="handleRequestPublic()">
        {{ 'pitchGrip:button.requestPublic' | i18next }}
    </button>
</div>
